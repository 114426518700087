
function CurriculumVitae() {

  const experiences = [
    {
      title: "Software Engineer",
      company: "Digita",
      date: "2020-Present",
      description: `My journey with Digita started with my internship at the end of my studies.
        Throughout my years at Digita, I have worked on many different projects, both internally as well as with external clients.
        From designing mockups, writing technical specifications, implementing them in various SPAs and/or APIs, 
        to automated testing as part of CI/CD pipelines, my work at Digita has varied greatly.`
    },
  ];

  const education = [
    {
      title: "Applied Informatics (Software Development)",
      school: "UCLL",
      date: "2017-2020",
      description: "Cum Laude"
    },
  ];

  const stack = [
    // languages
    [ "TypeScript", "JavaScript", "Python", "Go" ],
    // frameworks
    [ "Node.js", "Angular", "React" ],
    // Query languages
    [ "PostgreSQL", "SparQL", "GraphQL" ],
    // devops
    [ "DevOps", "GitHub Actions", "Terraform", "k8s" ],
    // cloud
    [ "AWS", "DigitalOcean", "Cloudflare" ]
  ];

  const languages = [
    "Dutch",
    "English"
  ];

  const certifications = [
    {
      title: "Professional Scrum Master 1",
      link: "https://www.scrum.org/user/1385718" 
    },
  ];

  const interests = [
    `In my free time, I like to indulge in music. Listening to music, creating music, collecting music, … You won't find me leaving the house without earbuds often!`,
    `Next to that, I've always enjoyed playing video games. Mostly on PC, although I also like to dust off my old consoles once in a while.`,
  ];

  const baseColors = [
    "primary",
    "primary-c",
    "primary-t1",
    "primary-t2",
    // "primary-t3",
    "secondary",
    "secondary-c",
    // "secondary-t1",
    "secondary-t2",
    // "secondary-t3",
    "tertiary",
    // "light",     = background color
    // "dark",      = text color
  ];

  const getColors = () => {
    return Array(stack.length).fill(baseColors.reduce((acc, curr) => {
        const randomIndex = Math.floor(Math.random() * (acc.length + 1));
        acc.splice(randomIndex, 0, curr);
        return acc;
      }, [])
    ).flat();
  }

  getColors();

  return (
    <div className="CurriculumVitae h-full flex-col m-auto p-5 max-w-screen-lg w-full lg:w-5/6 lg:p-20">
      <div className="m-5">
        <h1 className="font-bold text-xl">Stijn Taelemans</h1>
        <p className="font-semibold">Full-Stack Software Developer</p>
      </div>
      <div className="m-5">
        <h3 className="font-semibold mb-2 text-lg">Experience</h3>
        <ul>
          {
            experiences.map((e, i) => { 
                return (
                  <li key={i.toString()}>
                    <p className="font-semibold mb-2 mt-4">{e.title}, {e.company} — {e.date}</p>
                    <p>{e.description}</p>
                  </li>
                )
              })
            }
        </ul>
      </div>
      <div className="m-5">
        <h3 className="font-semibold mb-2 text-lg">Education</h3>
        <ul>
          {
            education.map((e, i) => { 
                return (
                  <li key={i.toString()}>
                    <p className="font-semibold mb-2 mt-4">{e.title}, {e.school} — {e.date}</p>
                    <p>{e.description}</p>
                  </li>
                )
              })
            }
        </ul>
      </div>
      <div className="m-5">
        <h3 className="font-semibold mb-2 text-lg">Stack</h3>
        <ul className="flex flex-wrap gap-1 text-xs wid max-w-screen-sm sepia">
          {
            stack.map((s, i) => {
              const colors = getColors();
              console.log(colors[i]);
              return s.map((l, j) => {
                console.log(colors[i]);
                const defaultClassName = 'items-center p-1 px-2 rounded-lg ring-2 ring-inset hover:ring-0 hover:ring-2 ';
                return colors[i] === 'primary' ? (
                  <li key={i.toString().concat(j.toString())} className={colors[i] ? defaultClassName + `ring-primary/90` : ''}>
                    <span>{l}</span>
                  </li>
                ) : colors[i] === 'secondary' ? (
                  <li key={i.toString().concat(j.toString())} className={colors[i] ? defaultClassName + `ring-secondary/90` : ''}>
                    <span>{l}</span>
                  </li>
                ) : colors[i] === 'tertiary' ? (
                  <li key={i.toString().concat(j.toString())} className={colors[i] ? defaultClassName + `ring-tertiary/90` : ''}>
                    <span>{l}</span>
                  </li>
                ) : colors[i] === 'primary-c' ? (
                  <li key={i.toString().concat(j.toString())} className={colors[i] ? defaultClassName + `ring-primary-c/90` : ''}>
                    <span>{l}</span>
                  </li>
                ) : colors[i] === 'primary-t1' ? (
                  <li key={i.toString().concat(j.toString())} className={colors[i] ? defaultClassName + `ring-primary-t1/90` : ''}>
                    <span>{l}</span>
                  </li>
                ) : colors[i] === 'primary-t2' ? (
                  <li key={i.toString().concat(j.toString())} className={colors[i] ? defaultClassName + `ring-primary-t2/90` : ''}>
                    <span>{l}</span>
                  </li>
                ) : colors[i] === 'secondary-c' ? (
                  <li key={i.toString().concat(j.toString())} className={colors[i] ? defaultClassName + `ring-secondary-c/90` : ''}>
                    <span>{l}</span>
                  </li>
                ) : colors[i] === 'secondary-t2' ? (
                  <li key={i.toString().concat(j.toString())} className={colors[i] ? defaultClassName + `ring-secondary-t2/90` : ''}>
                    <span>{l}</span>
                  </li>
                ) : console.error("Unknown color", colors[i]);
              })
          })
          }
        </ul>
      </div>
      <div className="m-5">
        <h3 className="font-semibold mb-2 text-lg">Certifications</h3>
        {
          certifications.map((c) =>
            (<p>{c.title} (<a href={c.link}>link</a>)</p>))
        }
      </div>
      <div className="m-5">
        <h3 className="font-semibold mb-2 text-lg">Languages</h3>
        <p>{ languages.join(", ") }</p>
      </div>
      <div className="m-5">
        <h3 className="font-semibold mb-2 text-lg">Other interests</h3>
        { interests.map((i) => (<p>{i}<br/><br/></p>)) }
      </div>
    </div>
)
}

export default CurriculumVitae;
